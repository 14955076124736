import { observer } from 'mobx-react';
import React, { useContext, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { __, CaseStatusList, CipheredToken, SndOfferAccepted } from '@adac/core-model';
import {
  BackButton,
  ButtonListStyles,
  CaseTokenParams,
  ChildrenProps,
  Driver,
  ErrorMessage,
  GetAllStore,
  getDriversToDispatch,
  LoadingSpinner,
  NetworkStoreLoader,
  Page,
  PostStore,
  Select,
  sndAcceptOffer,
  SubPage,
  Text,
  Timer,
  Title,
  useAsyncComponent,
  useLocation,
} from '@adac/core-view';

import StoresContext from '../../stores';

const SubContent = styled(SubPage)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LargeText = styled(Text)`
  font-size: ${(props) => props.theme.font.subTitle};
  margin-top: 8px;
`;

const ButtonContainer = styled(ButtonListStyles)`
  margin-top: 24px;
`;

interface DispatchToDriverProps extends RouteComponentProps<{ companyId?: string }>, ChildrenProps {}

export const DispatchToDriverScheduled = observer(({ match, children }: DispatchToDriverProps) => {
  const { goTo } = useLocation();
  const {
    ui: uiStore,
    case: { token: caseToken, accessToken, currentCompanyId, getDispatchDriverTimeLimit, hasError, handleStoreAction },
  } = useContext(StoresContext);
  const getCompanyDriverStoreContainer = useRef(new GetAllStore<Driver, CaseTokenParams<CipheredToken>>(getDriversToDispatch));
  const companyDriversStore = getCompanyDriverStoreContainer.current;

  const companyId = parseInt(match.params?.companyId ?? '', 10) || currentCompanyId;

  const firstDirverId =
    (companyDriversStore && companyDriversStore.items && companyDriversStore.items.length > 0 && companyDriversStore.items[0].id) || 0;

  const [selectedDriver, selectDriver] = useState(firstDirverId);

  const sendingDriverId = selectedDriver || firstDirverId;

  const onButtonClick = async () => {
    if (sendingDriverId <= 0) {
      uiStore.setOverlay(<ErrorMessage>{__('Driver was not set')}</ErrorMessage>);
    } else {
      if (!companyId) throw new Error(`companyID is missing: ${companyId}`);

      const sndAcceptOfferStore = new PostStore(sndAcceptOffer<SndOfferAccepted>);
      const item = await sndAcceptOfferStore.postItem(
        { caseToken, accessToken },
        {
          companyId,
          driverId: sendingDriverId,
        }
      );
      const status = await handleStoreAction(sndAcceptOfferStore, item);

      if (!hasError) {
        // shall redirect to main page, as we expected to receive the job, on SWA process
        if (
          `${currentCompanyId}` === `${companyId}` &&
          [`${CaseStatusList.SND_SCHEDULED}`, `${CaseStatusList.SCHEDULED_DRIVER_SELECTED}`].includes(status)
        )
          goTo('/');
      }
    }
  };

  const getErrorMessage = () =>
    (companyDriversStore.lastErrorMessage &&
      (companyDriversStore.lastErrorMessage.toLocalizedString
        ? companyDriversStore.lastErrorMessage.toLocalizedString()
        : companyDriversStore.lastErrorMessage.toString())) ||
    'Error';

  const { Component: AcceptJobButton } = useAsyncComponent({
    onClick: onButtonClick,
    enableAfterLoading: true,
  });

  return (
    <Page>
      <SubContent>
        <Title>{__('Timesuggestion Quesion1')}</Title>
        <LargeText>{__('RemainingTime')}</LargeText>
        <Timer startTime={getDispatchDriverTimeLimit} countdown onCountdown={<ErrorMessage>{__('TimeOver')}</ErrorMessage>} />
        <NetworkStoreLoader
          store={companyDriversStore}
          action={() => companyDriversStore.getAllItems({ caseToken, accessToken })}
          loadingView={<LoadingSpinner>{__('Loading drivers for your company')}...</LoadingSpinner>}
          errorView={
            <ErrorMessage>
              {getErrorMessage()} {__('on fetching drivers for the company')}
            </ErrorMessage>
          }
        >
          <Select
            id='name'
            name='name'
            options={companyDriversStore.items.map((driver) => ({
              ...driver,
              name: `${driver.firstName} ${driver.familyName}`,
            }))}
            value={selectedDriver}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => selectDriver(Number(e.target.value))}
          />
        </NetworkStoreLoader>

        {children}

        <ButtonContainer>
          <BackButton info title={__('Back')} />
          <AcceptJobButton type='submit' cta title={__('Send')} />
        </ButtonContainer>
      </SubContent>
    </Page>
  );
});
