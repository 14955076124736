import { Layout, ViewProvider, browser } from '@adac/core-view';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import StoreContext from '../../stores';
import { FinalFormBackButton } from '../composite/FinalFormBackButton';
import DevTools from '../dev/DevTools';
import OverlayErrorContainer from '../overlays/OverlayErrorContainer';
import FooterContainer from './Footer';
import Header from './Header';
import MainPageContainer from './MainPageContainer';
import OverlayContainer from './OverlayContainer';

const HeaderView = () => (
  <ViewProvider>
    <Header />
  </ViewProvider>
);

export const LayoutContainer = observer(() => {
  const stores = useContext(StoreContext);

  return (
    <ThemeProvider theme={stores.ui.currentTheme}>
      <Layout nav={HeaderView} browser={browser}>
        <MainPageContainer afterElement={<FooterContainer />} />
        <FinalFormBackButton />
        <OverlayContainer />
        <OverlayErrorContainer />
        <DevTools />
      </Layout>
    </ThemeProvider>
  );
});

export default LayoutContainer;
