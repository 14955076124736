/* eslint-disable no-return-await */
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';

import { CaseActions, CaseReportDTO, CaseStatusList, __, documentedDoorConditionConsentItems, formatDate } from '@adac/core-model';
import {
  AcceptConditionsContainer,
  ApiDataLoaded,
  Button,
  ButtonListStyles,
  InfoItem,
  LoadingSpinner,
  Page,
  ProductType,
  SignatureView,
  Spinner,
  SubPage,
  SubTitle,
  Timer,
  Title,
  UserInfo,
  request,
  useAsyncAction,
  useAsyncComponent,
  useLocation,
  useReportRequest,
} from '@adac/core-view';

import { useWorkflowDriverAction } from '../../hooks/useWorkflowDriverAction';
import StoreContext from '../../stores';
import { PossibleDamageConsent } from '../composite';
import PhotoList from '../composite/PhotoList';
import { routeNames } from '../layout/OverlayRouterContainer';
import Door from './Door';
import { TimerStyles } from './TimerStyles';

export const CommissionExtraDamage = observer(() => {
  const { ui: uiStore, case: caseStore, beforeExtraDamageStarting: doorStore } = useContext(StoreContext);

  const { goToWithRootId, goBack } = useLocation();

  const [signatureData, setSignatureData] = useState('');

  const { requestUrl, isView } = useReportRequest(caseStore.token, caseStore.currentCompanyId);

  const { hasLoaded, resource, isLoading } = useAsyncAction(async () => await request<CaseReportDTO>(requestUrl), [requestUrl]);

  const { extraDamageSignature, extraDamageExtraCost, case: caseObj } = resource || {};

  const user = {
    title: caseStore.title,
    firstName: caseStore.customerFirstName,
    lastName: caseStore.customerFamilyName,
    address: caseStore.customerFullAddressString,
  };

  const extraDamageAction = useWorkflowDriverAction(CaseActions.COMISSION_EXTRA_DAMAGE);

  const onNext = async () => {
    try {
      const result = await extraDamageAction({
        signature: signatureData,
        photos: doorStore.photosForPost,
        consentResults: caseStore.consentResults,
        productType: caseStore.productType,
      });

      if (result === CaseStatusList.EXTRA_DAMAGE_ONGOING || result === CaseStatusList.EDITING_EXTRA_DAMAGE)
        goToWithRootId(routeNames.comissionAdditional());
    } catch (error) {
      console.log(error);
    }
  };

  const { Component: ConfirmExtraDamageButton } = useAsyncComponent({
    onClick: onNext,
    disabled: doorStore.isLoading || doorStore.photosUploadedByUser?.length < 1 || !signatureData || !caseStore.extraDamageConsentsAccepted,
  });

  if (isLoading) {
    return (
      <Page>
        <SubPage>
          <Spinner />
        </SubPage>
      </Page>
    );
  }

  if (!resource) return null;

  const renderPhotos = () => {
    if (isView) {
      return (
        <PhotoList doorStore={doorStore} reportPhotos={extraDamageExtraCost && extraDamageExtraCost.photos}>
          {__('description of damage before door opening')}
        </PhotoList>
      );
    }

    if (doorStore.isLoading) {
      return <LoadingSpinner>{__('Photos are loading')}</LoadingSpinner>;
    }

    return <Door doorStore={doorStore} />;
  };

  return (
    <Page>
      <SubPage>
        <ApiDataLoaded hasLoaded={hasLoaded} />
        {!isView && (
          <TimerStyles>
            <Timer startTime={caseStore.getOpeningStartedTime} countdown={false}>
              <span>{__('In Arbeit')}:&nbsp;</span>
            </Timer>
          </TimerStyles>
        )}

        <Title>{__('Not possible without damage')}</Title>

        {isView && <UserInfo {...user} />}

        <ProductType productType={caseObj?.productType} productNumber={caseObj?.productNumber} damageNumber={caseStore.damageNumber} />

        <InfoItem header='Datum' info={formatDate(new Date())} />

        <PossibleDamageConsent />

        <SubTitle margin>{__('Photo of door extra damage')}</SubTitle>

        {renderPhotos()}

        <AcceptConditionsContainer caseStore={caseStore} readOnly={isView} consents={documentedDoorConditionConsentItems} />

        <SubTitle marginTop='32px'>{__('Signature')}</SubTitle>
      </SubPage>

      <SignatureView uiStore={uiStore} signatureData={isView ? extraDamageSignature : undefined} onSignatureChange={setSignatureData} />

      {!isView && (
        <SubPage>
          <ButtonListStyles>
            <Button info back title={__('Back')} onClick={() => goBack()} />
            <ConfirmExtraDamageButton cta title={__('Confirm')} />
          </ButtonListStyles>
        </SubPage>
      )}
    </Page>
  );
});
