import React from 'react';
import { __ } from '@adac/core-model';
import { Checkbox, SignatureView, Text, TextField, CheckboxItem, ErrorMessage, useView } from '@adac/core-view';

import { useFormikContext } from 'formik';
import StoreContext from '../../stores';
import { FinalReportFormValues } from '../pages/FinalReport';

interface FinalFormSignatureViewProps {
  finalReport: {
    signature?: string;
    notAccepted: boolean;
    reason?: string;
  };
}

const FinalFormSignatureView = ({ finalReport }: FinalFormSignatureViewProps) => {
  const { values, setFieldValue, errors } = useFormikContext<FinalReportFormValues>();
  const { isView } = useView();
  const { ui: uiStore } = React.useContext(StoreContext);
  const { notAccepted, signatureData } = values;
  return (
    <React.Fragment>
      {!notAccepted && (
        <SignatureView
          signatureData={isView ? finalReport?.signature : signatureData}
          uiStore={uiStore}
          onSignatureChange={(value: string) => setFieldValue('signatureData', value)}
        />
      )}

      <Checkbox
        id='notAccepted'
        labelTitle={__('Kunde verweigert Unterschrift')}
        checked={notAccepted}
        value='notAccepted'
        onChange={() => {
          setFieldValue('notAccepted', !notAccepted);
          setFieldValue('signatureData', '');
        }}
        render={CheckboxItem}
        disabled={isView}
      />

      {!isView && notAccepted && (
        <React.Fragment>
          <TextField
            name={__('reason')}
            badgeTitle={__('Grund')}
            rows={5}
            value={values.reason}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('reason', e.target.value)}
            badgeEqualsPlaceholder
          />
          {errors.reason && <ErrorMessage>{__('Please provide a cancellation reason with minimum 10 character')}</ErrorMessage>}
        </React.Fragment>
      )}

      {isView && finalReport?.reason && <Text>{`${__('Reason')}: ${finalReport?.reason}`}</Text>}
    </React.Fragment>
  );
};
export default FinalFormSignatureView;
