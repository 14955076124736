import { __, AdditionalCost, FinalReportType, getApiRoutes, ProductTypes } from '@adac/core-model';
import { Divider, getFinalReportTypeFromPathname, isIncludedInPremium, Text, useView, View } from '@adac/core-view';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { AdditionalCosts, CoveredCosts, TotalCost } from './Cost';
import { Dots } from './CostLayout';

const Styles = styled(View)`
  margin: 16px auto;
`;

const LargeText = styled(Text)`
  position: relative;
  overflow: hidden;
  text-align: left;
  font-size: ${(props) => props.theme.font.subTitle};
`;

interface Props {
  items?: AdditionalCost[];
  finalPrice?: number;
  finalReportType?: FinalReportType;
}

interface IncludedAndAdditionalCosts {
  includedCosts: string[];
  additionalCosts: AdditionalCost[];
  remainingCost: number;
}

const getIncludedAndAdditionalCosts = (costs: AdditionalCost[]): IncludedAndAdditionalCosts => {
  const { includedCosts, additionalCosts } = costs.reduce(
    (acc, curr) => {
      if (isIncludedInPremium(curr.description)) {
        return {
          ...acc,
          includedCosts: [...acc.includedCosts, curr.description],
        };
      }
      return { ...acc, additionalCosts: [...acc.additionalCosts, curr] };
    },
    { includedCosts: [] as string[], additionalCosts: [] as AdditionalCost[] }
  );

  const remainingCost = additionalCosts.reduce((acc, curr) => acc + curr.cost, 0);

  return { includedCosts, additionalCosts, remainingCost };
};

export const StandardPriceList = ({ finalPrice, items, finalReportType }: Props) => (
  <Styles>
    <LargeText bold>{__(`Surcharges:${finalReportType}`)}:</LargeText>

    <AdditionalCosts costs={items || []} amountComponent={Text} />

    <Divider color='black' fullWidth margin='20px 0px' />

    <TotalCost totalCost={finalPrice || 0} />
  </Styles>
);

export const PremiumPriceList = ({ items, finalReportType }: Props) => {
  const { includedCosts, additionalCosts, remainingCost } = getIncludedAndAdditionalCosts(items || []);
  return (
    <Styles>
      <LargeText bold>{__(`Surcharges:${finalReportType}`)}:</LargeText>

      <CoveredCosts costs={includedCosts} />

      {additionalCosts.length > 0 ? (
        <>
          <Text bold padding='8px 0 0 0' fontSize='1.3rem'>
            {__('Additional services')}:
          </Text>
          <AdditionalCosts costs={additionalCosts} amountComponent={Text} />
        </>
      ) : (
        <LargeText bold padding='8px 0 0 0'>
          {__('No Additional services')}
        </LargeText>
      )}

      <Divider color='black' fullWidth margin='20px 0px' />

      <TotalCost totalCost={remainingCost} />
    </Styles>
  );
};

const InsurancePriceListStyles = styled(Styles)`
  ${Dots}:after {
    content: none;
  }
`;

const InsurancePriceList = ({ items, finalReportType }: Props) => (
  <InsurancePriceListStyles>
    <LargeText bold>{__(`benefitsIncludedInInsurance:${finalReportType}`)}:</LargeText>
    <AdditionalCosts costs={items || []} amountComponent={() => <></>} />
  </InsurancePriceListStyles>
);

type PriceListProps = Props & {
  productType: ProductTypes | null;
  pathname: string;
};

export const PriceList = observer(({ productType, pathname, ...props }: PriceListProps) => {
  const pathnameWithoutToken = pathname.match(/\/final\/report\/((view\/\w+)|(active))/gi)?.[0];

  const { isView } = useView();
  const finalReportType = isView ? getFinalReportTypeFromPathname(pathname) : 'customer';

  if (!pathnameWithoutToken) {
    return <StandardPriceList {...props} finalReportType={finalReportType} />;
  }

  const insuranceReportPaths = [
    getApiRoutes().paths.pdf.finalStatusReport('active'),
    getApiRoutes().paths.pdf.finalStatusReport('view', 'customer'),
    getApiRoutes().paths.pdf.finalStatusReport('view', 'billing'),
  ];

  if (insuranceReportPaths.includes(pathnameWithoutToken) && productType === ProductTypes.INSURANCE) {
    return <InsurancePriceList {...props} finalReportType={finalReportType} />;
  }

  const premiumReportPaths = [
    getApiRoutes().paths.pdf.finalStatusReport('active'),
    getApiRoutes().paths.pdf.finalStatusReport('view', 'billing'),
    getApiRoutes().paths.pdf.finalStatusReport('view', 'customer'),
  ];

  if (premiumReportPaths.includes(pathnameWithoutToken) && productType === ProductTypes.PREMIUM) {
    return <PremiumPriceList {...props} finalReportType={finalReportType} />;
  }

  return <StandardPriceList {...props} finalReportType={finalReportType} />;
});
