import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { TextField, SubPage, View, Text, DoorPhotoStore } from '@adac/core-view';

import { __ } from '@adac/core-model';
import { FullScreenPhoto } from '../composite';
import ImageCarousel from '../composite/photoCarousel/ImageCarousel';

const TextFieldStyles = styled(View)`
  textarea {
    height: 9rem;
  }
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

interface Props {
  readonly?: boolean;
  doorStore: DoorPhotoStore;
}

export default observer(({ readonly, doorStore }: Props) => {
  const [current, setCurrent] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  function deletePhoto(index: number) {
    doorStore.deletePhotoAtIndex(index);
  }

  function arePhotosAvailable() {
    return (
      doorStore && doorStore.photosUploadedByUser && doorStore.photosUploadedByUser.length > 0 && current < doorStore.photosUploadedByUser.length
    );
  }

  function getDescription() {
    return (arePhotosAvailable() && doorStore.photosUploadedByUser[current].description) || '';
  }

  function setDescription(description: string) {
    if (arePhotosAvailable()) {
      doorStore.setDescriptionAtIndex(current, description);
    }
  }

  function getFullScreenPhoto() {
    return (
      <FullScreenPhoto
        index={current + 1}
        photoUrl={doorStore.photosUploadedByUser[current].url}
        description={doorStore.photosUploadedByUser[current].description}
        onCloseClicked={() => setFullScreen(false)}
      />
    );
  }

  function getCarousel() {
    return (
      <>
        <ImageCarousel
          doorStore={doorStore}
          currentIndex={arePhotosAvailable() ? current : undefined}
          onIndexChange={(index) => setCurrent(index)}
          onDelete={readonly ? undefined : deletePhoto}
          onFullScreen={setFullScreen}
        />
        <SubPage>
          {arePhotosAvailable() && current < doorStore.photosUploadedByUser.length && (
            <TextFieldStyles>
              {readonly ? (
                <>
                  <BoldText>{__('description')}</BoldText>
                  <Text>{getDescription()}</Text>
                </>
              ) : (
                <TextField
                  badgeTitle='Kurzbeschreibung der Schadensituation'
                  placeholder='Text hier eintippen…'
                  rows={5}
                  value={getDescription()}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => setDescription(e.currentTarget.value)}
                />
              )}
            </TextFieldStyles>
          )}
        </SubPage>
      </>
    );
  }

  return fullScreen ? getFullScreenPhoto() : getCarousel();
});
