import { __ } from '@adac/core-model';
import { Page, SubPage, Title, Text, ChildrenProps } from '@adac/core-view';

export const CaseCompleted = ({ children }: ChildrenProps) => (
  <Page>
    <SubPage>
      <Title margin>{children}</Title>
      <Text margin>{__('Thank you for your service, we sent the invoice to the customer.')}</Text>
    </SubPage>
  </Page>
);
