import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { Page, SubPage, Title, Button, useLocation, useAsyncComponent, Icon, LoadingSpinner } from '@adac/core-view';

import { __, isValidMobilePhoneNumber } from '@adac/core-model';
import styled from 'styled-components';
import Door from './Door';
import StoreContext from '../../stores';
import { routeNames } from '../layout/OverlayRouterContainer';
import { useWorkflowCallCustomerAction } from '../../hooks/useWorkflowCallCustomerAction';

const CallButton = styled(Button)`
  &,
  & > a {
    flex-direction: row-reverse;
    gap: 3px;
  }
`;

const ComissionOpeningForm = observer(() => {
  const doorStore = useContext(StoreContext).beforeOpeningDoorPhotos;
  const { goTo } = useLocation();
  const { case: caseStore } = React.useContext(StoreContext);

  const callCustomerAction = useWorkflowCallCustomerAction();

  const { Component: CallCustomerButton } = useAsyncComponent({
    Component: CallButton,
    onClick: () =>
      callCustomerAction({
        companyId: caseStore.currentCompanyId,
        driverId: caseStore.driverInfo?.driver?.id,
        number: caseStore.customerPhone,
      }),
    enableAfterLoading: true,
  });

  const isPhotoLoading = doorStore.isLoading;

  return (
    <Page>
      <SubPage>
        {isValidMobilePhoneNumber(caseStore.customerPhone) && (
          <CallCustomerButton link={`tel: ${caseStore.customerPhone}`} info title={__('Make a customer call')} margin>
            <Icon name='phone' />
          </CallCustomerButton>
        )}

        <Title>{__('previousDamageDetection')}</Title>
        {isPhotoLoading ? <LoadingSpinner>{__('Photos are loading')}</LoadingSpinner> : <Door doorStore={doorStore} />}

        {/* <Text margin color="#666666">Sollten keine Vorschäden vorhanden sein, fotografieren Sie bitte die unbeschädigte Tür und notieren keine Vorschäden.</Text> */}
        {/* <Checkbox
          id="isThereNoDamage"
          labelTitle={__('No damage')}
          checked={isThereNoDamage}
          onChange={() => setIsThereNoDamage(prevState => !prevState)}
          value="isThereNoDamage"
          render={CheckboxItem}
        /> */}

        <Button
          cta
          disabled={doorStore.photosUploadedByUser.length === 0 || isPhotoLoading}
          title='Weiter'
          onClick={() => goTo(routeNames.commissionReport('confirm'))}
        />
      </SubPage>
    </Page>
  );
});

export default ComissionOpeningForm;
