import { __, doorConditionConsentItems } from '@adac/core-model';
import { AcceptConditionsContainer, Text, useReportRequest, View } from '@adac/core-view';
import { useContext } from 'react';
import StoreContext from '../../../stores';

export function PossibleDamageConsent() {
  const { case: caseStore } = useContext(StoreContext);

  const { isView } = useReportRequest(caseStore.token, caseStore.currentCompanyId);

  return (
    <View marginTop='16px' marginBottom='16px'>
      <Text small bold>
        {__('Explanation of Possible Damage')}
      </Text>
      <Text>{__('Information on Possible Damages')}</Text>

      <AcceptConditionsContainer caseStore={caseStore} readOnly={isView} consents={doorConditionConsentItems} />

      <Text>{__('Is possible')}</Text>
      <Text marginTop='16px'>{__('Door opening acknowledgement')}</Text>
    </View>
  );
}
