import { CipheredToken } from '@adac/core-model';
import { useContext } from 'react';
import StoresContext from '../stores';

const { REACT_APP_SND_ADMIN_PORTAL_URL: badTokenUrl } = process.env;

export function useRedirectWithCaseToken() {
  /**
   * Avoid destructuring fields from `stores.case`, as it may cause the store initialization to fail.
   */
  const stores = useContext(StoresContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function setCaseToken(urlToken: string, _unused: unknown, urlCompanyId?: string) {
    if (urlCompanyId && !stores.case.currentCompanyId) {
      stores.case.setCurrentCompanyId(urlCompanyId);
    }

    const { token, companyId } = await stores.case.fetchByToken(urlToken as CipheredToken);

    // If the token starts with '+' and a token is present, return early
    if (urlToken.startsWith('+') && token) {
      return;
    }

    // If no token is found and the current path doesn't match the bad token URL pattern, redirect
    if (!token && !window.location.pathname.match(new RegExp(`^${badTokenUrl}`, 'gi'))) {
      window.location.href = `${badTokenUrl}`;
      return;
    }

    // If the URL token does not match the fetched token, redirect to the correct URL
    if (urlToken !== token && token) {
      const newPath = companyId ? `/${token}/${companyId}` : `/${token}`;
      window.location.href = newPath;
    }
  }

  return setCaseToken;
}

export function useRedirectWithAccessToken() {
  /**
   * Avoid destructuring fields from `stores.case`, as it may cause the store initialization to fail.
   */
  const stores = useContext(StoresContext);

  async function setCaseAndAccessToken(caseToken: CipheredToken, accessToken: string) {
    stores.case.setAccessToken(accessToken);
    const { token } = await stores.case.fetchByToken(caseToken);
    if (token) {
      window.location.href = `/${token}`;
    }
  }

  return setCaseAndAccessToken;
}
