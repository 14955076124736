import { Icon, NavigatorProps } from '@adac/core-view';
import styled from 'styled-components';

const NavigatorButtonStyles = styled.button<NavigatorProps>`
  outline: none;
  border: none;

  width: 24px;
  height: 24px;

  background-color: ${(props) => (props.active ? props.theme.colors.cta : props.theme.colors.divider)};
  border-radius: 12px;

  position: absolute;
  z-index: 10;
  top: 50%;
  ${(props) => (props.left ? 'left: 8px' : 'right: 8px')};
  transform: translateY(-50%);

  & > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
`;

export const CarouselNavigatorButton = (props: NavigatorProps) => {
  const { left } = props;
  return (
    <NavigatorButtonStyles type='button' {...props}>
      <Icon name={left ? 'back' : 'right'} color='white' />
    </NavigatorButtonStyles>
  );
};
