import { useContext, useState } from 'react';

import {
  Page,
  SubPage,
  Title,
  ErrorMessage,
  Timer,
  CostSummary,
  FlexView,
  Text,
  themeConfig,
  Icon,
  Button,
  GenericCostItem,
  CostFormProvider,
} from '@adac/core-view';
import { __ } from '@adac/core-model';

import { observer } from 'mobx-react';
import styled from 'styled-components';
import StoreContext from '../../stores';
import { TimerStyles } from './TimerStyles';
import { ConfirmExtraCostsButton } from '../composite/extraCost/ConfirmExtraCostsButton';
import { InfoModal } from '../composite/extraCost/ExtraCostInfoModal';

const TitleContainer = styled(FlexView)`
  align-items: center;
  gap: 5px;
`;

const TitleRow = styled(FlexView)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Parentheses = styled(Text)`
  font-size: ${(props) => props.theme.font.smallText};
  font-weight: normal;
  height: 100%;
  margin: 0 0 8px 0;
`;

const CommissionExtraCost = observer(() => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const { case: caseStore } = useContext(StoreContext);

  return (
    <Page>
      <InfoModal visible={isInfoModalVisible} onClose={() => setIsInfoModalVisible(false)} />
      <SubPage>
        <TimerStyles>
          <Timer startTime={caseStore.getOpeningStartedTime} countdown={false}>
            <span>{__('In Arbeit')}:&nbsp;</span>
          </Timer>
        </TimerStyles>

        <TitleRow>
          <TitleContainer>
            <Title margin>{__('Additional costs title')}</Title>
            <Parentheses>{__('Additional cost parentheses')}</Parentheses>
          </TitleContainer>
          <Button onClick={() => setIsInfoModalVisible(true)}>
            <Icon name='info' color={themeConfig.colors.cta} size='24px' />
          </Button>
        </TitleRow>

        {caseStore.additionalCost && caseStore.additionalCost.length > 0 && (
          <ErrorMessage>{__('Warning: you have already set additonal costs before')}</ErrorMessage>
        )}

        <CostFormProvider storeItems={caseStore.extraCostItems}>
          <CostSummary total={caseStore.extraCostTotal}>
            {caseStore.extraCostItems.map((item) => {
              const CostItemComponent = item.type as GenericCostItem;
              return (
                <div key={item.id}>
                  {item.separator}
                  {item.category}
                  <CostItemComponent {...item} setItem={(id, value) => caseStore.setExtraCostItem(id, value)} />
                </div>
              );
            })}
          </CostSummary>

          <ConfirmExtraCostsButton />
        </CostFormProvider>
      </SubPage>
    </Page>
  );
});

export default CommissionExtraCost;
