/* eslint-disable no-return-await */
import {
  Page,
  PhotoFromBackend,
  Spinner,
  SubPage,
  concatSchemas,
  extractAddressLocationNameObject,
  request,
  useAsyncAction,
  useReportRequest,
} from '@adac/core-view';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { useContext } from 'react';

import { AccuracyType, CaseReportDTO, base64ImageRegex, isValidEmail, viewFinalReportStates } from '@adac/core-model';

import * as yup from 'yup';
import { useFinalReportSubmitHandler } from '../../hooks/useFinalReportSubmitHandler';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import StoreContext from '../../stores';
import { BillingAddressDataValues, BillingAddressNameSchema, emptyDefaultAddress } from './BillingAddressForm';
import FinalReportForm from './FinalReportForm';

const photoSchema = yup.object({
  photos: yup
    .array()
    .of(
      yup.object({
        image: yup.string().matches(base64ImageRegex).required(),
        text: yup.string(),
      })
    )
    .required()
    .min(1),
});

const finalReportValidationSchema = (baseSchema: yup.AnyObjectSchema) =>
  concatSchemas(photoSchema, baseSchema)?.concat(
    yup.object().shape({
      email: yup.string().when('receiveByEmail', {
        is: true,
        then: (schema) => schema.test('isValidEmail', 'Invalid email address', (value) => isValidEmail(value)).required('Email is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      reason: yup.string().when('notAccepted', {
        is: true,
        then: (schema) => schema.min(10, 'Cancellation reason must be at least 10 characters').required('Cancellation reason is required'),
        otherwise: (schema) => schema.optional(),
      }),
      signatureData: yup.string().when('notAccepted', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional(),
      }),
      notAccepted: yup.boolean(),
    })
  );

export interface FinalReportFormValues extends BillingAddressDataValues {
  receiveByEmail: boolean;
  email: string | undefined | null;
  notAccepted: boolean;
  reason: string;
  showAddress: boolean;
  signatureData: string;
  photos: PhotoFromBackend[];
}

const FinalReport = observer(() => {
  const { case: caseStore, afterOpeningDoorPhotos: doorStore } = useContext(StoreContext);

  const { requestUrl, isView } = useReportRequest(caseStore.token, caseStore.currentCompanyId);

  const action = useAsyncAction(async () => await request<CaseReportDTO>(requestUrl), [requestUrl]);

  const { resource, isLoading } = action;

  const billingAddressFromServer = resource?.billingAddress;

  const isInsurance = useIsInsuranceProduct();
  const validationSchema = finalReportValidationSchema(isInsurance ? yup.object({}) : BillingAddressNameSchema);

  const initialValues = {
    ...((billingAddressFromServer && extractAddressLocationNameObject(billingAddressFromServer)) || emptyDefaultAddress),
    receiveByEmail: !!resource?.case?.customerEmail,
    email: resource?.case?.customerEmail,
    notAccepted: resource?.finalReport?.notAccepted || false,
    reason: '',
    showAddress: false,
    signatureData: '',
    confidence: 1,
    accuracyType: 'houseNumber' as AccuracyType,
    photos: doorStore.photosForPost,
  };

  const handleSubmit = useFinalReportSubmitHandler(billingAddressFromServer);

  if ((isView && !viewFinalReportStates.includes(caseStore.status)) || !resource) {
    return null;
  }

  if (isLoading) {
    return (
      <Page>
        <SubPage>
          <Spinner />
        </SubPage>
      </Page>
    );
  }

  return (
    <Page>
      <SubPage>
        <Formik initialValues={initialValues} validationSchema={validationSchema} isInitialValid={isView} onSubmit={handleSubmit} enableReinitialize>
          <FinalReportForm action={action} />
        </Formik>
      </SubPage>
    </Page>
  );
});

export default FinalReport;
