import { observer } from 'mobx-react';
import { useContext } from 'react';

import { BasicDevTool, View } from '@adac/core-view';

import StoreContext from '../../stores';

export default observer(() => {
  const stores = useContext(StoreContext);

  return (
    <BasicDevTool stores={stores}>
      <h1>Form input details</h1>
      <section>
        <View height='120px' fullWidth style={{ overflow: 'scroll', maxWidth: '300px' }}>
          <small>
            {stores.case.customerFullAddressString}
            <br />
            {stores.case.customerCoordsString}
            <br />
            Selected Company ID for case: {stores.case.selectedCompanyId}
            <br />
            Current Company: {stores.case.currentCompanyId}
          </small>
        </View>
      </section>
    </BasicDevTool>
  );
});
