import { AddressForm, InputLayout, getBillingCoordsByAddress, isBillingAddressReady, Checkbox, CheckboxItem } from '@adac/core-view';
import { AddressFormValues, __ } from '@adac/core-model';
import { Field, FieldProps, useFormikContext } from 'formik';

export interface ConfirmedAddressFormValues extends AddressFormValues {
  addressConfirmed: boolean;
}

export const ConfirmedAddressForm = () => {
  const {
    values: { addressConfirmed },
  } = useFormikContext<ConfirmedAddressFormValues>();

  return (
    <>
      <Field
        name='addressConfirmed'
        type='checkbox'
        render={({ field }: FieldProps<'addressConfirmed', ConfirmedAddressFormValues>) => (
          <Checkbox
            {...field}
            id='addressConfirmed'
            labelTitle={__('Do you confirm this address location?')}
            checked={addressConfirmed}
            render={CheckboxItem}
          />
        )}
      />

      {!addressConfirmed && (
        <InputLayout>
          <AddressForm
            showLocationBtn={false}
            showEditButton={false}
            isAddressReady={isBillingAddressReady}
            getCoordsByAddress={getBillingCoordsByAddress}
          />
        </InputLayout>
      )}
    </>
  );
};
