import { __ } from '@adac/core-model';
import { FlexView, Icon, useView, View } from '@adac/core-view';
import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import { HeaderCaseInfoContainer } from './HeaderCaseInfoContainer';
import { PhoneContactModal } from './PhoneContact';

const AdacLogo = () => <Icon name='adac' type='ico.logo' size='24px' />;

const SndDriverLogo = () => <>{__('SND Driver portal')}</>;

type WithLinkProps = {
  children: ReactElement;
};

const WithLink = observer(({ children }: WithLinkProps) => {
  const isInsurance = useIsInsuranceProduct();
  if (isInsurance) return children;

  return <Link to='/'>{children}</Link>;
});

const Header = () => {
  const { isView } = useView();

  return (
    <FlexView start='true' center>
      <View padding>
        <WithLink>
          <AdacLogo />
        </WithLink>
      </View>
      <View padding='5px 0px'>
        <View background='white' padding='4px' compact={false}>
          <WithLink>
            <SndDriverLogo />
          </WithLink>
        </View>
      </View>
      <HeaderCaseInfoContainer />
      {!isView && <PhoneContactModal />}
    </FlexView>
  );
};

export default Header;
